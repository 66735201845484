// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../components/SEO'

// Container
import BeerUReportContainer from '../../../containers/BeerUReportContainer'

// Layout
import LayoutMain from '../../../layout'

const BeerU: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="BeerU" />
      <WithPrivateRoute component={BeerUReportContainer} />
    </LayoutMain>
  )
}

export default BeerU

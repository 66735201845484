/* eslint-disable no-console */
// Packages
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, NetworkStatus } from '@apollo/client'
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

// Styled Componets
import {
  StyledBackAndFilterReportsBeerUContainer,
  StyledButtonBackReports,
  StyledTitleEmptyData,
  StyledEmptyData,
  StyledButtonLoadMore,
  StyledRowContainerOrders,
  StyledColContainerOrders
} from './styledComponents'

// Components
import { StyledSelectContainer } from '../../components/ReportContent/styledComponents'
import { StoresSelect } from '../../components/StoresSelect'
import ReportBeerU, {
  OrderBeerUTypes,
  OrderDataTypes
} from '../../components/ReportBeerU'
import Loading from '../../components/Shared/Loading'
import IconComponent from '../../components/Icon'

// Graphql
import { GET_REPORT_BEER_U } from '../../graphql/queries/Reports'

// Redux
import { setStoreData } from '../../redux/ducks/stores/actionsCreators'

// Types
import { reducersTypes } from '../../redux/reducers'
import { StoresTypes } from '../../redux/ducks/profile/types'

// Assets
import deliveryIcon from '../../data/assets/icons/deliveryIcon.webp'
import pickupIcon from '../../data/assets/icons/pickupIcon.webp'
import emptyIcon from '../../data/assets/icons/reports-data-empty.svg'
import { customEventGA } from '../../utils/googleAnalitycs'

// Lodash
import { isEmpty, uniqBy } from 'lodash'

type DeliveryTypes = {
  deliveryDate: string
  note: string
  address: string
}

type OmniChannelOrdersTypes = {
  accountID: string
  clientName: string
  commentStatus: Array<string>
  contactPhone: string
  delivery: DeliveryTypes
  items: Array<OrderDataTypes>
  orderID: string
  orderIsComplete: boolean
  orderType: string
  placeOrderDate: string
  statusID: number
  storeID: string
  totalAmount: number
}

const BeerUReportContainer: React.FC = () => {
  const [userList, setUserList] = useState<any[]>([])
  const [fullyLoaded, setFullyLoaded] = useState(false)
  const [fetchToken, setFetchToken] = useState('')
  const [loadingOrders, setLoadingOrders] = useState(false)

  const { user_data } = useSelector((state: reducersTypes) => state.profile)
  const { storeID, marketplace, franchise } = useSelector(
    (state: reducersTypes) => state.stores
  )

  const {
    data: dataReportBeerU,
    fetchMore,
    networkStatus
  } = useQuery(GET_REPORT_BEER_U, {
    variables: {
      requestConfig: {
        marketplace,
        franchise
      },
      storeID,
      orderID: ''
    },
    context: {
      clientName: 'api-instore'
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  const dispatch = useDispatch()

  const setDataStore = (store: string) => {
    user_data.getProfile.stores.forEach((storeItem: StoresTypes) => {
      if (storeItem.storeID === store) {
        dispatch(
          setStoreData({
            storeID: store,
            franchise: storeItem.vtexFranchise,
            marketplace: storeItem.vtexMarketplace
          })
        )
      }
    })
  }

  const handleSelectOrder = (orderData: OrderBeerUTypes) => {
    customEventGA({
      eventAction: 'select_history_order',
      eventCategory: orderData.orderType,
      eventLabel: 'report_rewards'
    })
    navigate(
      `/reports/beeru/order?storeId=${storeID}&orderId=${orderData.orderID}`
    )
  }

  const headerFilter = (
    <StyledSelectContainer align="middle">
      <StoresSelect
        onChange={(storeID) => setDataStore(storeID)}
        store={storeID}
        stores={user_data.getProfile.stores}
      />
    </StyledSelectContainer>
  )

  const backAndFilterContainer = (
    <StyledBackAndFilterReportsBeerUContainer>
      <StyledButtonBackReports
        onClick={() => {
          navigate('/reports')
        }}
      >
        <LeftOutlined />
        {'Historial de órdenes de Beer U'}
      </StyledButtonBackReports>
    </StyledBackAndFilterReportsBeerUContainer>
  )

  const emptyMessage = (
    <StyledEmptyData>
      <IconComponent icon={emptyIcon} width={160} height={160} />
      <StyledTitleEmptyData>No hay órdenes registradas</StyledTitleEmptyData>
    </StyledEmptyData>
  )

  const reportBeerUContent = isEmpty(userList)
    ? emptyMessage
    : userList.map((item: OmniChannelOrdersTypes) => {
        const orderData = {
          orderID: item.orderID,
          orderType: item.orderType,
          status:
            dataReportBeerU?.orderOmnichannelList?.statusList[item.statusID - 1]
              ?.title,
          colorStatus:
            dataReportBeerU?.orderOmnichannelList?.statusList[item.statusID - 1]
              ?.hexColor,
          deliveryDate: item.delivery.deliveryDate,
          items: item.items,
          address: item.delivery.address,
          phone: item.contactPhone
        }
        return (
          <ReportBeerU
            key={item.orderID}
            orderBeerUData={orderData}
            handleCLick={handleSelectOrder}
            orderTypeIcon={
              item.orderType === 'DELIVERY' ? deliveryIcon : pickupIcon
            }
          />
        )
      })

  const spinLoading = (
    <LoadingOutlined style={{ fontSize: 24, paddingLeft: '5px' }} spin />
  )

  const loadMoreContent = (
    <StyledButtonLoadMore
      onClick={async () => {
        setLoadingOrders(true)
        const result = await fetchMore({
          variables: {
            token: fetchToken
          }
        })
        const newArray = uniqBy(
          [
            ...userList,
            ...(result.data.orderOmnichannelList.omnichannelOrders as any[])
          ],
          'orderID'
        ) as any[]
        setUserList(newArray)
        setFetchToken(result.data.orderOmnichannelList.token)
        setFullyLoaded(result.data.orderOmnichannelList.token.length === 0)
        setLoadingOrders(false)
      }}
      disabled={loadingOrders}
    >
      Mostrar más ordenes {loadingOrders && <Spin indicator={spinLoading} />}
    </StyledButtonLoadMore>
  )

  useEffect(() => {
    if (
      dataReportBeerU &&
      networkStatus !== NetworkStatus.fetchMore &&
      fetchToken === '' &&
      !fullyLoaded
    ) {
      const newArray = uniqBy(
        dataReportBeerU?.orderOmnichannelList?.omnichannelOrders as any[],
        'orderID'
      ) as any[]
      setUserList(newArray)
      // userList = userList.concat(newArray)
      console.log(newArray)
      // fetchToken = dataReportBeerU?.orderOmnichannelList?.token || ''
      setFetchToken(dataReportBeerU?.orderOmnichannelList?.token || '')
    }
  }, [dataReportBeerU])

  console.log(userList, fullyLoaded)

  if (networkStatus === NetworkStatus.loading) return <Loading />

  return (
    <React.Fragment>
      {headerFilter}
      {backAndFilterContainer}
      <StyledRowContainerOrders>
        <StyledColContainerOrders>
          {reportBeerUContent}
          {userList.length && !fullyLoaded && loadMoreContent}
        </StyledColContainerOrders>
      </StyledRowContainerOrders>
    </React.Fragment>
  )
}

export default BeerUReportContainer

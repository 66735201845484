// Packages
import styled from 'styled-components'

type StyledStatusReportBeerUTypes = {
  colorStatus: string
}

export const StyledReportBeerUContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
`

export const StyledReportBeerUContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledReportBeerUButtonOrderDetail = styled.span`
  display: flex;
  align-items: center;
  color: #0b4793;
  span svg {
    width: 25px;
    height: 30px;
  }
`

export const StyledTitleReportBeerU = styled.p`
  font-size: 13px;
  font-weight: 700;
  margin: 0;
`

export const StyledTypeReportBeerU = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: #0b4793;
  margin: 0;
  text-transform: capitalize !important;
`

export const StyledTypeIconReportBeerU = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`

export const StyledDescriptionReportBeerU = styled.p`
  font-size: 13px;
  margin: 0;
`

export const StyledStatusReportBeerU = styled.span<StyledStatusReportBeerUTypes>`
  color: ${({ colorStatus }) => colorStatus};
  font-weight: 600;
  margin-left: 8px;
`

// Packages
import styled from 'styled-components'

export const StyledBackAndFilterReportsBeerUContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
`

export const StyledButtonBackReports = styled.div`
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  height: 47px;
  display: flex;
  align-items: center;
  span {
    margin: 0 9px 0;
  }
`

export const StyledSeparatorFilter = styled.div`
  font-size: 40px;
  border-left: 1px solid;
  width: 1px;
  height: 40px;
  color: #d2d2d2;
`

export const StyledFilterBeerUContainer = styled.div`
  width: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  img {
    margin: 0 15px 0 0;
    transform: translateY(0);
  }
`

export const StyledEmptyData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 0 3px 0;
    transform: none;
  }
`

export const StyledTitleEmptyData = styled.p`
  font-size: 16px;
  color: #828282;
`

export const StyledButtonLoadMore = styled.button`
  height: 40px;
  width: 256px;
  border-radius: 100px;
  font-weight: 600;
  margin: 15px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: rgb(11, 71, 147);
  color: white;
  margin-bottom: 50px;
  padding: 10px;
  align-self: flex-end;

  :disabled {
    background-color: rgb(210, 210, 210);
    color: white;
  }

  :disabled:hover {
    background-color: rgb(210, 210, 210);
    color: white;
    cursor: not-allowed;
  }
`

export const StyledRowContainerOrders = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const StyledColContainerOrders = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`

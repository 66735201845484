// Packages
import React from 'react'
import { RightOutlined } from '@ant-design/icons'
import moment from 'moment'

// Styled Components
import {
  StyledReportBeerUContainer,
  StyledReportBeerUContent,
  StyledReportBeerUButtonOrderDetail,
  StyledTitleReportBeerU,
  StyledTypeReportBeerU,
  StyledTypeIconReportBeerU,
  StyledDescriptionReportBeerU,
  StyledStatusReportBeerU
} from './styledComponents'

export type OrderDataTypes = {
  sku: string
  quantity: number
  price: number
  total: number
  name: string
  stockComplete: null | string
  brandName: string
  measurementMultiplier: string
  measurementUnit: string
  packaging: string
  units: number
  isReturnable: boolean
  eansSingle: Array<string>
}

export type OrderBeerUTypes = {
  orderID: string
  orderType: string
  status: string
  colorStatus: string
  deliveryDate: string
  items: Array<OrderDataTypes> | []
  address: string
  phone: string
  finishDate?: string
  clientName?: string
}

type props = {
  orderBeerUData: OrderBeerUTypes
  orderTypeIcon: string
  handleCLick: (orderData: OrderBeerUTypes) => void
}

const ReportBeerU: React.FC<props> = ({
  orderBeerUData,
  orderTypeIcon,
  handleCLick
}) => {
  const { orderID, orderType, status, colorStatus, deliveryDate } =
    orderBeerUData
  return (
    <>
      <StyledReportBeerUContainer>
        <StyledReportBeerUContent>
          <StyledTitleReportBeerU>{`Orden ${orderID}`}</StyledTitleReportBeerU>
          <StyledTypeReportBeerU>
            {orderType.toLowerCase()}
            <StyledTypeIconReportBeerU src={orderTypeIcon} />
          </StyledTypeReportBeerU>
          <StyledDescriptionReportBeerU>
            Estatus:
            <StyledStatusReportBeerU colorStatus={colorStatus}>
              {status}
            </StyledStatusReportBeerU>
          </StyledDescriptionReportBeerU>
          <StyledDescriptionReportBeerU>
            {`Completada el ${moment(deliveryDate).format('DD-MM-YYYY')}`}
          </StyledDescriptionReportBeerU>
        </StyledReportBeerUContent>
        <StyledReportBeerUButtonOrderDetail
          data-testid="button-detail-order-beer-u"
          onClick={() => handleCLick(orderBeerUData)}
        >
          <RightOutlined />
        </StyledReportBeerUButtonOrderDetail>
      </StyledReportBeerUContainer>
    </>
  )
}

export default ReportBeerU
